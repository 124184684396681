.pageLayout {
  margin-top: 60px;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1976d2;
  border-radius: 50px;
  padding: 12px 20px 12px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
  font-size: 16px;
  height: 54px;
}

.btn:hover {
  background-color: rgb(243, 243, 243);
}

