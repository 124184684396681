.pageLayout {
  margin-top: 60px;
  padding-left: 150px;
}

.gridLayout {
  width: 100%;
  max-width: 1200px;
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .pageLayout {
    margin-top: 60px;
    padding-left: 0px;
  }
}

.buttons {
  display: flex;
  align-items: center;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1976d2;
  border-radius: 50px;
  padding: 12px 20px 12px 20px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
  margin: 10px;
  font-size: 16px;
  height: 54px;
  text-align: center;
}

.btn:hover {
  background-color: rgb(243, 243, 243);
}
