.pageContainer {
    /* padding-left: 150px; */
    background-color: #EEEEEE;
    padding-bottom: 96px;
    padding-left: 30px;
    min-height: 85%;
    min-height: -webkit-calc(100% - 186px);
    min-height: -moz-calc(100% - 186px);
    min-height: calc(100% - 186px);
  }
  
  
  .stat {
    margin-top: 30px;
  }
  
  
  /* .featuresContainer {
      margin: 0px auto;
      width: 100%;
      padding: 0px 60px 20px 60px;
    
     
    } */
    
    /*
        AUTO GRID
        Set the minimum item size with `--auto-grid-min-size` and you'll
        get a fully responsive grid with no media queries.
      */
      .autoGrid {
        --auto-grid-min-size: 18rem;
        width: 90%;
        margin: 10px auto;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0.5rem;
      }
       
      .listItem {
        display: flex;
        position: relative;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 120px;
        min-height: 110px;
        padding: 1rem;
        list-style-type: none;
        text-align: center;
        border-bottom: 1px solid rgb(184, 184, 184);
        border-radius: 4px;
        background-color: white;
        cursor: pointer;
      }
  
      
      .listItem h2 {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 10px;
        padding: 0px 7px;
        cursor: pointer;
        color: rgb(156, 156, 156);
        margin-top: 0.25rem;
        margin-bottom: 0.5rem;
      }

      .listItem p {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        line-height: 10px;
        padding: 5px;
        cursor: pointer;
      }

      .storeName {
        font-size: 18px;
      }
      
      .itemInfo {
        text-align: left;
        font-family: 'Roboto', sans-serif;
        font-weight: 100;
        font-size: 14px;
        line-height: 10px;
        padding: 5px;
        cursor: pointer;
      }
      
      .cardTitle {
        background-color: #1976d2;
        height: 25px;
        width: 100%;
      }
      
      .listItem h1 {
        text-align: center;
        font-size: 26px;
        line-height: 20px;
        text-align: left;
        padding: 0px 25px 5px 5px;
        font-family: 'Roboto', sans-serif;
        font-weight: 100;
        cursor: pointer;
      }
      
      .listItem:hover {
        background-color: #f8f8f8;
       
      }
      
      .listItem:hover h2 {
        color: rgb(0, 0, 0);
       
      }
      
      .routeFunctions {
        width: 20%;
        position: relative;
        display: inline-block;
      }

      .routeDropdown {
          position: absolute;
          top: 100%;
          left: 0;
          width: 300px;
          z-index: 2;
          border: 1px solid rgba(0, 0, 0, 0.04);
          box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
      }

      .routeDropdown ul {
        list-style: none;
        padding: 0;
        margin: 0;
      }

      .routeDropdown li {
        padding: 8px 12px;
      }
      
      .routeDropdown li:hover {
        background-color: rgba(0, 0, 0, 0.14);
        cursor: pointer;
      }
  
      .grid {
        --auto-grid-min-size: 20rem;
        width: 85%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(
          auto-fill,
          minmax(var(--auto-grid-min-size), 1fr)
        );
        grid-gap: 0rem;
      }
  
  
        
      .entry {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-width: 120px;
        height: 60px;
        padding: 1rem 2rem;
        list-style-type: none;
        text-align: left;
        border-bottom: 1px solid rgb(224, 224, 224);
        /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
        border-radius: 0px;
        background-color: white;
      }
  
  
      .grid button {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: none;
        background-color: #1976d2;
        color: white;
         box-shadow: 0 3px 6px rgba(0, 0, 0, 0.068), 0 3px 6px rgba(0, 0, 0, 0.096);
      }
  
  
      .donutAndRecentPayment {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 97%;
        margin: 0px auto;
      }
  
      .recentPayment {
        padding: 20px;
        width: 65%;
        min-height: 200px;
      }
  
      .donut {
        width: 35%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 74px;
        margin-left: -50px;
        padding: 28px;
        margin-top: 52px;
        border-bottom: 1px solid rgb(184, 184, 184);
        /* box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23); */
        border-radius: 4px;
      }
  
     
  
      .smallText {
        font-size: 13px;
        color: gray;
      }
  
      .donut {
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
  
      .table {
        width: 90%;
        margin: 0 auto;
        overflow-x:auto
  
      }
  
      .table table {
        border-collapse:separate; 
        border-spacing:0 0px;
        /* overflow-x: auto; */
        white-space: nowrap;
      }
      
  
      .table button {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: none;
        background-color: #1976d2;
        color: white;
         box-shadow: 0 3px 6px rgba(0, 0, 0, 0.068), 0 3px 6px rgba(0, 0, 0, 0.096);
      }
  
  
      .table td {
        padding: 15px;
        border: none;
        background-color: white;
        border-bottom: 1px solid rgb(223, 223, 223);
       
      }
  
  
    
      @media all and (max-width: 768px) {
  
        .pageContainer {
          padding-left: 0px;
        }
  
        .table {
          width: 95%;
    
        }
      
        .items {
          width: 100%;
        }
  
        .donutAndRecentPayment {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
  
        .recentPayment {
          width: 100%;
        }
    
        .donut {
          width: 100%;
        }
  
        .grid {
          --auto-grid-min-size: 20rem;
          width: 100%;
        }
  
        .donut {
          margin-left: 40px;
        }
  
        /* .table {
          width: 40%;
        }
        */
      }
      
  
  